:root {
  --mainBlue:#30318C;
  --mainGreen:#39B54A;
  --mainWhite:#ffffff;
  --mainBlack:#000000;

}
.roboto {
  font-family: 'Roboto', sans-serif;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.center {
  text-align:center;
}
.ib {
  display: inline-block;
}
.relative {
  position: relative;
}